import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VContainer,[(!_vm.escuelaTienePermiso)?_c(VSkeletonLoader,{attrs:{"type":"list-item-two-line@5"}}):_c(VCard,{attrs:{"rounded":"lg"}},[_c(VToolbar,{staticClass:"text-h6",attrs:{"dense":"","color":"secondary","dark":"","flat":""}},[_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.regresarVideos()}}},[_c(VIcon,[_vm._v("mdi-arrow-left")])],1),_c(VSpacer),_vm._v(" VIDEOS "),_c(VSpacer),(_vm.esAdmin)?_c(VMenu,{attrs:{"offset-y":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","small":"","disabled":_vm.loading}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-dots-vertical")])],1)]}}],null,false,1053987487)},[_c(VList,{attrs:{"dense":""}},[_c(VListItem,{on:{"click":function($event){_vm.editarNombre = true}}},[_c(VListItemTitle,[_vm._v("Modificar nombre del video")]),_c(VListItemIcon,[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1)],1)],1)],1):_vm._e()],1),(!_vm.loading)?_c(VContainer,[_c(VCardTitle,[_c(VBtn,{attrs:{"icon":"","disabled":_vm.preVideo ? false : true,"color":"primary"},on:{"click":function($event){return _vm.changeVideo(_vm.preVideo)}}},[_c(VIcon,[_vm._v("mdi-chevron-left")])],1),_c(VSpacer),_vm._v(" "+_vm._s(_vm.video.nombre)+" "),_c(VSpacer),_c(VBtn,{attrs:{"icon":"","disabled":_vm.nextVideo ? false : true,"color":"primary"},on:{"click":function($event){return _vm.changeVideo(_vm.nextVideo)}}},[_c(VIcon,[_vm._v("mdi-chevron-right")])],1)],1),_c(VDivider,{staticClass:"mx-3"}),_c(VContainer,[(!_vm.video.file)?_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VIcon,[_vm._v("mdi-video-box-off")]),_vm._v(" No se ha subido un video ")],1)],1):_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c('video-player',{ref:"videoPlayer",attrs:{"options":_vm.playerOptions,"playsinline":true}})],1)],1)],1),_c(VDivider,{staticClass:"mx-3"}),_c(VContainer,[_c(VToolbar,{attrs:{"dense":"","flat":""}},[(!_vm.video.descripcion && !_vm.edicionHabilitada)?_c('span',[_c('i',[_vm._v("No se ha capturado descripción")])]):_vm._e(),_c(VSpacer),(_vm.esAdmin)?_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('button',_vm._g({staticStyle:{"float":"right"},on:{"click":function($event){return _vm.habilitarEdicion()}}},on),[_c(VIcon,{staticStyle:{"margin-right":"5px"},attrs:{"medium":""}},[_vm._v("mdi-square-edit-outline")])],1)]}}],null,false,410987489)},[_c('span',[_vm._v("Modificar descripción")])]):_vm._e()],1),(_vm.edicionHabilitada)?_c('div',[_c('editor-texto',{ref:"editorTexto",attrs:{"texto":_vm.video.descripcion,"app":"app","contenido":'cursoCampus',"idContenido":_vm.video._id}}),_c('div',{staticStyle:{"text-align":"right"}},[_c(VSpacer),_c(VBtn,{staticClass:"margen",attrs:{"color":"primary","outlined":"","small":"","disabled":_vm.loadingDescripcion},on:{"click":function($event){_vm.edicionHabilitada = false}}},[_vm._v(" Cancelar ")]),_c(VBtn,{staticClass:"margen",attrs:{"color":"primary","dark":"","small":"","loading":_vm.loadingDescripcion},on:{"click":function($event){return _vm.editarDescripcionvideo()}}},[_vm._v(" Guardar ")])],1)],1):_c('div',{domProps:{"innerHTML":_vm._s(_vm.video.descripcion)}})],1)],1):_c('loadingVideo')],1)],1),_c('editor-nombre-video',{attrs:{"mostrar":_vm.editarNombre,"nombreVideo":_vm.video.nombre,"idVideo":_vm.video._id},on:{"cancelar":function($event){_vm.editarNombre = false},"nombreEditado":_vm.nombreEditado}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }