<template>
  <div>
    <v-container>
      <v-skeleton-loader
        v-if="!escuelaTienePermiso"
        type="list-item-two-line@5"
      ></v-skeleton-loader>
      <v-card v-else rounded="lg">
        <v-toolbar dense color="secondary" dark flat class="text-h6">
          <v-btn icon @click="regresarVideos()"
            ><v-icon>mdi-arrow-left</v-icon></v-btn
          >

          <v-spacer></v-spacer> VIDEOS <v-spacer></v-spacer>
          <v-menu offset-y left v-if="esAdmin">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon small v-bind="attrs" v-on="on" :disabled="loading">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item @click="editarNombre = true">
                <v-list-item-title
                  >Modificar nombre del video</v-list-item-title
                >
                <v-list-item-icon
                  ><v-icon small>mdi-pencil</v-icon></v-list-item-icon
                >
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar>
        <v-container v-if="!loading">
          <v-card-title>
            <v-btn
              icon
              :disabled="preVideo ? false : true"
              @click="changeVideo(preVideo)"
              color="primary"
              ><v-icon>mdi-chevron-left</v-icon></v-btn
            >
            <v-spacer></v-spacer> {{ video.nombre }} <v-spacer></v-spacer>
            <v-btn
              icon
              :disabled="nextVideo ? false : true"
              color="primary"
              @click="changeVideo(nextVideo)"
              ><v-icon>mdi-chevron-right</v-icon></v-btn
            >
          </v-card-title>
          <v-divider class="mx-3"></v-divider>
          <v-container>
            <v-row v-if="!video.file">
              <v-col cols="12">
                <v-icon>mdi-video-box-off</v-icon>
                No se ha subido un video
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col cols="12">
                <video-player
                  ref="videoPlayer"
                  :options="playerOptions"
                  :playsinline="true"
                />
              </v-col>
            </v-row>
          </v-container>
          <v-divider class="mx-3"></v-divider>
          <v-container>
            <v-toolbar dense flat>
              <span v-if="!video.descripcion && !edicionHabilitada"
                ><i>No se ha capturado descripción</i></span
              >
              <v-spacer></v-spacer>
              <v-tooltip top v-if="esAdmin">
                <template v-slot:activator="{ on }">
                  <button
                    style="float: right"
                    v-on="on"
                    @click="habilitarEdicion()"
                  >
                    <v-icon medium style="margin-right: 5px"
                      >mdi-square-edit-outline</v-icon
                    >
                  </button>
                </template>
                <span>Modificar descripción</span>
              </v-tooltip>
            </v-toolbar>
            <div v-if="edicionHabilitada">
              <editor-texto
                ref="editorTexto"
                :texto="video.descripcion"
                app="app"
                :contenido="'cursoCampus'"
                :idContenido="video._id"
              ></editor-texto>
              <div style="text-align: right">
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  class="margen"
                  outlined
                  small
                  :disabled="loadingDescripcion"
                  @click="edicionHabilitada = false"
                >
                  Cancelar
                </v-btn>
                <v-btn
                  color="primary"
                  class="margen"
                  dark
                  small
                  :loading="loadingDescripcion"
                  @click="editarDescripcionvideo()"
                >
                  Guardar
                </v-btn>
              </div>
            </div>
            <div v-else v-html="video.descripcion"></div>
          </v-container>
        </v-container>
        <loadingVideo v-else />
      </v-card>
    </v-container>
    <editor-nombre-video
      :mostrar="editarNombre"
      :nombreVideo="video.nombre"
      :idVideo="video._id"
      @cancelar="editarNombre = false"
      @nombreEditado="nombreEditado"
    />
  </div>
</template>

<script>
import "video.js/dist/video-js.css";
import { videoPlayer } from "vue-video-player";
import { mapGetters } from "vuex";
import editorTexto from "../contenidos/editorTexto.vue";
import loadingVideo from "./loadingVideos.vue";
import editorNombreVideo from "./editorNombreVideo.vue";
import {
  getVideoService,
  modificarDescripcionVideoService,
} from "./videos.service";
import { permisoMenuEscuela } from '../../helpers/utilerias';

export default {
  name: "vistaVideo",
  components: {
    videoPlayer,
    "editor-texto": editorTexto,
    loadingVideo,
    editorNombreVideo,
  },
  computed: {
    ...mapGetters(["sessionToken", "app", "videoURL", "role", "menu"]),
    esAdmin() {
      return (
        this.app == "campus" || this.role == "admin" || this.role == "profesor"
      );
    },
  },
  watch: {
    sessionToken(value) {
      if (value) {
        if (!permisoMenuEscuela("Videos")) this.$router.push("/dashboard");
        else {
          this.escuelaTienePermiso = true;
          this.cargarVideo();
        }
      }
    },

    "video.route": function(nuevo) {
      this.playerOptions.sources[0].src = this.videoURL + "/" + nuevo;
    },
    "$route.params"() {
      this.cargarVideo();
    },
  },
  mounted() {
    if (this.sessionToken) {
      if (!permisoMenuEscuela("Videos")) this.$router.push("/dashboard");
      else {
        this.escuelaTienePermiso = true;
        this.cargarVideo();
      }
    }
  },

  data() {
    return {
      loading: false,
      escuelaTienePermiso: false,
      loadingDescripcion: false,
      preVideo: null,
      nextVideo: null,
      playerOptions: {
        responsive: true,
        muted: false,
        language: "en",
        playbackRates: [0.5, 1.0, 1.5, 2.0],
        sources: [
          {
            type: "video/mp4",
            src: "https://www.youtube.com/watch?v=fEa84OPxLY4&ab_channel=AGNUS",
          },
        ],
        poster: "/img/imagen.png",
      },
      edicionHabilitada: false,
      hayvideoSiguiente: true,
      hayvideoPrevia: true,
      video: {
        _id: "",
        nombre: "",
        route: "",
      },
      area: null,
      subarea: null,
      editarNombre: false,
    };
  },
  methods: {
    async cargarVideo() {
      const data = {
        area: this.$route.params.idArea,
        subarea: this.$route.params.subarea,
        idVideo: this.$route.params.idVideo,
      };
      try {
        this.loading = true;
        const serverResponse = await getVideoService(data);
        this.loading = false;
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else {
          this.video = serverResponse.video;
          this.playerOptions.sources[0].src = this.videoURL + this.video.route;
          this.preVideo = serverResponse.previous;
          this.nextVideo = serverResponse.next;
        }
      } catch (error) {
        this.$appErrorMessage();
        this.loading = false;
      }
    },
    regresarVideos() {
      this.$router.push(`/videos`);
    },

    changeVideo(idVideo) {
      this.$router.push(
        `/video/${this.$route.params.idArea}/${this.$route.params.subarea}/${idVideo}`
      );
    },
    habilitarEdicion() {
      this.edicionHabilitada = true;
    },
    async editarDescripcionvideo() {
      this.loadingDescripcion = true;
      try {
        const data = {
          descripcion: this.$refs.editorTexto.obtenerTexto(),
        };
        const serverResponse = await modificarDescripcionVideoService(
          data,
          this.video._id
        );
        this.loadingDescripcion = false;

        if (!serverResponse.ok) {
          this.$systemErrorMessage(serverResponse.mensaje);
        } else {
          this.video.descripcion = serverResponse.descripcion;
          this.edicionHabilitada = false;
        }
      } catch (error) {
        this.$appErrorMessage();
        this.loadingDescripcion = false;
      }
    },
    nombreEditado(nombre) {
      this.video.nombre = nombre;
      this.editarNombre = false;
    },
  },
};
</script>

<style>
.margen {
  margin: 5px !important;
}
</style>
