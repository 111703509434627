<template>
  <v-dialog v-model="mostrar" persistent width="450">
    <v-card :loading="loading">
      <v-toolbar dense color="primary" dark flat>
        <v-toolbar-title>Modificar nombre</v-toolbar-title>
      </v-toolbar>
      <v-card-text class="pt-3 pb-0 px-4">
        <v-row dense>
          <v-col cols="12">
            <v-text-field
              v-model="nombre"
              label="Nombre"
              outlined
              dense
              :disabled="loading"
              :error-messages="errors.nombre"
              @input="$v.nombre.$touch()"
              @blur="$v.nombre.$touch()"
              @keyup.enter="submit()"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          small
          color="secondary"
          outlined
          :disabled="loading"
          @click="$emit('cancelar')"
          >Cancelar</v-btn
        >
        <v-btn
          small
          color="primary"
          :disabled="loading || !esValido"
          :loading="loading"
          @click="submit()"
          >Guardar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { modificarNombreVideoService } from "./videos.service";

export default {
    mixins: [validationMixin],

    props: {
      mostrar: { type: Boolean, default: false },
      nombreVideo: { type: String, default: () => "" },
      idVideo: {type: String, default: () => null}
    },
    watch:{
      "nombreVideo":function(val){
        if(val != '') {
        this.nombre = val
      }
    }
    },
    computed: {
        errors() {
        const errors = {
            nombre: [],
        };

        if (!this.$v.nombre.$dirty) return errors;
        !this.$v.nombre.required && errors.nombre.push("Campo requerido.");

        return errors;
        },

        esValido() {
        if (this.errors.nombre.length) return false;
        return true;
        },
    },

    validations: {
        nombre: { required },
    },

    data: () => ({
        loading: false,
        nombre: "",
    }),

    created(){
      this.nombre = this.nombreVideo
    }, 
    methods: {
      async submit() {
          this.$v.nombre.$touch();
          if (!this.esValido) return;

          this.loading = true;
              try {
              this.loading = true;
              const data = {
              nombre: this.nombre,
              };
              const serverResponse = await modificarNombreVideoService(data,this.idVideo);
              this.loading = false;

              if (!serverResponse.ok) {
              this.$systemErrorMessage(serverResponse.mensaje);
              } else {
              this.$emit("nombreEditado",serverResponse.nombre)
              this.$emit("cancelar")
              }
          } catch (error) {
              this.$appErrorMessage();
              this.loading = false;
          }
      },
  },
};
</script>